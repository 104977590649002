#Impressum {
  width: 100%;
  height: calc(100vh - 6.25rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    h1 {
      text-align: center;
    }
  }
}