// Reset
body, html {
  background-color: #f5f5f5;
  color: #0c0c0c;
  font-family: 'Inter', Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3d3d3d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e0e0e;
}
