p {
  font-size: calc(0.4vw + 0.7rem) !important;
  font-weight: 200 !important;
  line-height: calc(0.4vw + 0.95rem) !important;
  margin-bottom: 0 !important;
  max-width: 95vw;
}

h1 {
  font-size: calc(0.6vw + 1rem) !important;
  font-weight: 800 !important;
  max-width: 95vw;
  margin-bottom: 0;
}

h2 {
  font-size: calc(0.6vw + 0.9rem) !important;
  font-weight: 600 !important;
  max-width: 95vw;
  margin-bottom: 0;
}

h3 {
  font-size: calc(0.6vw + 0.8rem) !important;
  font-weight: 600 !important;
  max-width: 95vw;
  margin-bottom: 0;
}

h4 {
  font-size: calc(0.6vw + 0.7rem) !important;
  font-weight: 600 !important;
  max-width: 95vw;
  margin-bottom: 0;
}

i {
  font-size: 1.5rem;
  filter: drop-shadow(0 0 0.05rem #072d50);
  color: #072d50; /* Lila Farbakzent */
  &:hover {
    filter: brightness(150%);
  }
}

a {
  font-size: calc(0.6vw + 0.5rem) !important;
  font-weight: 600 !important;
  line-height: 1.15 !important;
  margin-bottom: 0 !important;
}